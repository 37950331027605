import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { triggerDownload } from '../system/redux/reducers/qrcode'
import QRCodeStyling from "qr-code-styling"

// References: https://codesandbox.io/s/qr-code-styling-react-example-l8rwl?file=/src/App.js:1474-1483
// https://qr-code-styling.com/
// https://github.com/kozakdenys/qr-code-styling
let qrCode = false

if (typeof window !== 'undefined') {
    qrCode = new QRCodeStyling({
        width: 250,
        height: 250,
        image: "",
        dotsOptions: {
            color: "#000",
            type: "rounded"
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
        }
    })
}
const QrCodeDownloader = ({ dispatch, baseUrl, urlKey, download, extension, name }) => {

    const [url, setUrl] = useState('')

    useEffect(() => {
        if (urlKey) {
            setUrl(`${baseUrl}/${urlKey}`)
        }
    }, [baseUrl, urlKey, extension])
    
    useEffect(() => {
        if (qrCode) {
            qrCode.update({
                data: url
            })
        }
    }, [url])

    useEffect(() => {
        if (download && urlKey !== '' && url !== '' && qrCode && extension) {
            qrCode.download({
                name,
                extension
            })
            dispatch(triggerDownload(JSON.stringify({ urlKey: '', download: false, name: '' })))
        }
    }, [download, urlKey, url, extension])

    return null
}


function mapStateToProps(state) {
    return {
        baseUrl: state.Globals.baseUrl,
        name: state.QrCode.name,
        download: state.QrCode.download,
        urlKey: state.QrCode.urlKey,
        extension: state.QrCode.extension
    }
}

export default connect(mapStateToProps)(QrCodeDownloader)