import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import {
    CHART_BAR_SIZE,
    SORT_DIR,
    QR_VIEWS,
    ANALYTICS_MAX_ROWS,
} from '../helpers/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faChevronDown,
    faChevronUp,
    faCircleDown,
} from '@fortawesome/free-solid-svg-icons';
import IconLink from './IconLink';
import QrItemContainer from './QrItemContainer';
import QrItemHeader from './QrItemHeader';
import QrItemInner from './QrItemInner';
import QrItemFooter from './QrItemFooter';
import { collection, getDocs, query, where, doc } from 'firebase/firestore';
import { db } from '../system/firebase';
import Loading from './Loading';
import {
    flattenObject,
    getDotNotationValue,
    constructDownloadName,
} from '../helpers/functions';
import { format as dateFormat, parse as dateParse } from 'date-fns';
import 'chartjs-adapter-date-fns';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

// TODO: Once data is set in stone, the analytics could maybebe calculated in a Firestore Trigger (onVisitsWrite) and stored in a separate collection (analytics)?
//  - that way we don't have to calculate it every time the user visits a listing details page

function countVisitsBy(visits, key, converterFunc = false) {
    const groupedVisits = new Map();
    for (const visit of visits) {
        // normalise options to arrays
        if (typeof key === 'string') key = [key];
        if (typeof converterFunc === 'function')
            converterFunc = [converterFunc];

        /* eslint-disable no-loop-func */
        const combinedValue = key
            .map((key, i) => {
                let value = getDotNotationValue(visit, key);

                if (value === undefined) return undefined;

                if (Array.isArray(converterFunc) && converterFunc[i]) {
                    value = converterFunc[i](value);
                }

                return value;
            })
            .filter((val) => val !== undefined)
            .join('||');
        /* eslint-enable no-loop-func */

        if (combinedValue === '') continue;

        if (!groupedVisits.has(combinedValue)) {
            groupedVisits.set(combinedValue, 1);
        } else {
            groupedVisits.set(
                combinedValue,
                groupedVisits.get(combinedValue) + 1
            );
        }
    }
    return Array.from(groupedVisits.entries());
}

function reduceGroupedVisitsByDate(groupedVisits) {
    return Array.from(
        groupedVisits
            .reduce((reducedVisits, [key, count]) => {
                const [date] = key.split('||');

                if (!reducedVisits.has(date)) reducedVisits.set(date, 1);
                else reducedVisits.set(date, reducedVisits.get(date) + 1);

                return reducedVisits;
            }, new Map())
            .entries()
    );
}

const AnalyticsGrid = ({ children }) => {
    const theme = useTheme();

    return (
        <Grid
            container
            sx={{
                '& > div + div': {
                    md: {
                        borderTop: `1px solid ${theme.palette.grey.lighter}`,
                    },
                },
            }}
        >
            {children}
        </Grid>
    );
};

const AnalyticsGridRow = ({ children }) => {
    const theme = useTheme();

    return (
        <Grid
            container
            position="relative"
            item
            xs={12}
            sx={{
                '& > .analytics-grid-item + .analytics-grid-item': {
                    md: {
                        borderLeft: `1px solid ${theme.palette.grey.lighter}`,
                    },
                },
            }}
        >
            {children}
        </Grid>
    );
};

const AnalyticsGridItem = ({ children }) => {
    const theme = useTheme();

    return (
        <Grid
            item
            className="analytics-grid-item"
            p={4}
            sx={{
                width: '100%',
                [theme.breakpoints.up('sm')]: { width: '50%' },
            }}
        >
            {children}
        </Grid>
    );
};

const AnalyticsTable = ({
    label,
    groupedVisits,
    totalVisits,
    initialSortDir = SORT_DIR.DESC,
    maxRows = ANALYTICS_MAX_ROWS,
    asBarChart = false,
}) => {
    const theme = useTheme();
    const [sortDir, setSortDir] = useState(initialSortDir);
    const [sortedGroupedVisits, setSortedGroupedVisits] = useState(false);

    useEffect(() => {
        const sortedGroupedVisits = [...groupedVisits].sort((a, b) => {
            if (sortDir === SORT_DIR.ASC) {
                return a[1] - b[1];
            } else {
                return b[1] - a[1];
            }
        });

        if (maxRows !== false) sortedGroupedVisits.splice(maxRows);

        setSortedGroupedVisits(sortedGroupedVisits);
    }, [groupedVisits, sortDir, maxRows]);

    return (
        <Table
            sx={{
                '& th, & td': {
                    padding: theme.spacing(2, 0),
                    fontWeight: 'bold',
                    borderBottom: 'none',
                },
                '& th': { color: theme.palette.grey.lite },
                '& th.scans, & td.scans': {
                    paddingLeft: asBarChart ? theme.spacing(3) : null,
                    paddingRight: asBarChart ? theme.spacing(3) : null,
                },
                '& td.scans': {
                    color: theme.palette.green.main,
                },
            }}
        >
            <TableHead>
                <TableRow>
                    {asBarChart ? null : <TableCell width="10%">#</TableCell>}
                    <TableCell width={asBarChart ? null : '60%'}>
                        {label}
                    </TableCell>
                    <TableCell
                        className="scans"
                        width={asBarChart ? '99%' : null}
                    >
                        Scans
                    </TableCell>
                    <TableCell align="right">%</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedGroupedVisits === false
                    ? null
                    : sortedGroupedVisits.map(([key, count], index) => (
                          <TableRow key={key}>
                              {asBarChart ? null : (
                                  <TableCell>{index + 1}</TableCell>
                              )}
                              <TableCell>{key}</TableCell>
                              <TableCell className="scans">
                                  {asBarChart ? (
                                      <Box
                                          width={`${(
                                              (count / totalVisits) *
                                              100
                                          ).toFixed(0)}%`}
                                          height={`${CHART_BAR_SIZE}px`}
                                          sx={{
                                              background:
                                                  theme.palette.gradient
                                                      .reversed,
                                          }}
                                      />
                                  ) : (
                                      count
                                  )}
                              </TableCell>
                              <TableCell align="right">
                                  {((count / totalVisits) * 100).toFixed(0)}%
                              </TableCell>
                          </TableRow>
                      ))}
            </TableBody>
        </Table>
    );
};

const AnalyticsOverTimeBarChart = ({ groupedVisitsByDate }) => {
    const theme = useTheme();

    // https://www.chartjs.org/docs/latest/samples/advanced/linear-gradient.html
    let width, height, gradient; // cached values
    function getGradient(ctx, chartArea) {
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;

        // only update if size has changed
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(
                0,
                chartArea.bottom,
                0,
                chartArea.top
            );
            gradient.addColorStop(0, theme.palette.gradient.end);
            gradient.addColorStop(1, theme.palette.gradient.start);
        }

        return gradient;
    }

    return (
        <Bar
            data={{
                datasets: groupedVisitsByDate.map((currGroup) => ({
                    label: currGroup.label,
                    data: currGroup.data.map(([key, count]) => ({
                        x: key,
                        y: count,
                    })),
                    maxBarThickness: CHART_BAR_SIZE,
                    backgroundColor: currGroup?.colour
                        ? currGroup.colour
                        : (context) => {
                              const chart = context.chart;
                              const { ctx, chartArea } = chart;
                              if (!chartArea) return; // ignore until chart is setup
                              return getGradient(ctx, chartArea);
                          },
                })),
            }}
            options={{
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            parser: 'yyyy-MM-dd',
                            unit: 'day',
                        },
                        grid: {
                            display: false,
                        },
                        min: () =>
                            dateFormat(
                                new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
                                'yyyy-MM-dd'
                            ),
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 1,
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        labels: {
                            boxWidth: CHART_BAR_SIZE,
                            boxHeight: CHART_BAR_SIZE,
                        },
                    },
                },
            }}
        />
    );
};

const ShowMoreToggle = ({ showMore, setShowMore }) => {
    const theme = useTheme();

    return (
        <Box
            onClick={() => setShowMore(!showMore)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '0',
                left: '50%',
                backgroundColor: theme.palette.white.main,
                padding: theme.spacing(2),
                transform: 'translateX(-50%) translateY(50%)',
                zIndex: 10,
                cursor: 'pointer',
                color: theme.palette.blue.main,
            }}
        >
            <Typography variant="link" mr={1}>
                {showMore ? 'Show Less' : 'Show More'}
            </Typography>
            <FontAwesomeIcon
                icon={showMore ? faChevronUp : faChevronDown}
                size="xs"
            />
        </Box>
    );
};

const QrViewDetails = ({ setParentView, data }) => {
    const theme = useTheme();
    const [visits, setVisits] = useState(false);
    const [analytics, setAnalytics] = useState(false);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        async function getVisits() {
            const listingRef = doc(db, 'listings', data.docId);
            const docSnap = await getDocs(collection(listingRef, 'visits'));
            const visits = docSnap.docs.map((doc) => doc.data());
            setVisits(visits);
        }

        getVisits();
    }, [data.docId]);

    useEffect(() => {
        if (visits === false) return;

        const analytics = {
            visitsByCombinedIpUa: countVisitsBy(visits, ['ip', 'userAgent']),
            visitsByDate: countVisitsBy(visits, 'timestamp', (timestamp) =>
                dateFormat(new Date(timestamp), 'yyyy-MM-dd')
            ),
            visitsByCombinedDateIpUa: countVisitsBy(
                visits,
                ['timestamp', 'ip', 'userAgent'],
                [(timestamp) => dateFormat(new Date(timestamp), 'yyyy-MM-dd')]
            ),
            visitsByOs: countVisitsBy(visits, 'system.os.name'),
            visitsByCountry: countVisitsBy(visits, 'geo.country_name'),
            visitsByCity: countVisitsBy(visits, 'geo.city'),
        };

        setAnalytics(analytics);
    }, [visits]);

    const downloadCsv = () => {
        const flattenedVisits = visits.map(flattenObject);

        if (flattenedVisits.length === 0) return;

        const columnHeaders = Object.keys(flattenedVisits[0]);
        columnHeaders.sort();

        const csv = ''.concat(
            columnHeaders
                .map((column) => `"${column.replaceAll('"', '\\"')}"`)
                .join(',') + '\n',
            flattenedVisits
                .map((row) =>
                    columnHeaders
                        .map((column) =>
                            !row[column]
                                ? ''
                                : `"${row[column]
                                      .toString()
                                      .replaceAll('"', '\\"')}"`
                        )
                        .join(',')
                )
                .join('\n')
        );

        // output the csv for download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
        const fileName = constructDownloadName(data.name, data.date_added);
        link.setAttribute('download', `${fileName}-details.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <QrItemContainer>
            <QrItemHeader
                title={`Details | ${data.name}`}
                rightActions={
                    <IconLink
                        icon={<FontAwesomeIcon icon={faArrowLeft} />}
                        text="Back"
                        onClick={() => setParentView(QR_VIEWS.EDIT)}
                    />
                }
            />
            <QrItemInner>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    pb={3}
                    mb={5}
                    borderBottom={`1px solid ${theme.palette.grey.lighter}`}
                >
                    <Typography fontSize="18px">
                        <strong>Total Scans:</strong> {visits.length}{' '}
                        {analytics && analytics?.visitsByCombinedIpUa
                            ? `/ ${analytics.visitsByCombinedIpUa.length} Unique`
                            : ''}
                    </Typography>
                    <Typography fontSize="18px">
                        <strong>Date Created:</strong>{' '}
                        {dateFormat(
                            dateParse(
                                data.date_added + '+00',
                                'dd/MM/yyyy HH:mm:ssX',
                                new Date()
                            ),
                            'dd/MM/yyyy'
                        )}
                    </Typography>
                </Box>

                <Grid container mb={5}>
                    {analytics === false ? (
                        <Loading color="black.main" />
                    ) : (
                        <AnalyticsGrid>
                            <AnalyticsGridRow>
                                <ShowMoreToggle
                                    showMore={showMore}
                                    setShowMore={setShowMore}
                                />
                                <AnalyticsGridItem>
                                    <Typography
                                        display="block"
                                        variant="innerHeader"
                                        mb={2}
                                    >
                                        Scans Over Time
                                    </Typography>
                                    <AnalyticsOverTimeBarChart
                                        groupedVisitsByDate={[
                                            {
                                                label: 'Total',
                                                data: analytics.visitsByDate,
                                            },
                                            {
                                                label: 'Unique',
                                                colour: theme.palette.blue.main,
                                                data: reduceGroupedVisitsByDate(
                                                    analytics.visitsByCombinedDateIpUa
                                                ),
                                            },
                                        ]}
                                    />
                                </AnalyticsGridItem>
                                <AnalyticsGridItem>
                                    <Typography
                                        display="block"
                                        variant="innerHeader"
                                        mb={2}
                                    >
                                        Scans by Operating System
                                    </Typography>
                                    <AnalyticsTable
                                        label="OS"
                                        groupedVisits={analytics.visitsByOs}
                                        totalVisits={visits.length}
                                        maxRows={
                                            showMore
                                                ? false
                                                : ANALYTICS_MAX_ROWS
                                        }
                                        asBarChart={true}
                                    />
                                </AnalyticsGridItem>
                            </AnalyticsGridRow>
                            {showMore ? (
                                <AnalyticsGridRow>
                                    <AnalyticsGridItem>
                                        <Typography
                                            display="block"
                                            variant="innerHeader"
                                            mb={2}
                                        >
                                            Scans by Top Countries
                                        </Typography>
                                        <AnalyticsTable
                                            label="Country"
                                            groupedVisits={
                                                analytics.visitsByCountry
                                            }
                                            totalVisits={visits.length}
                                            maxRows={
                                                showMore
                                                    ? false
                                                    : ANALYTICS_MAX_ROWS
                                            }
                                        />
                                    </AnalyticsGridItem>
                                    <AnalyticsGridItem>
                                        <Typography
                                            display="block"
                                            variant="innerHeader"
                                            mb={2}
                                        >
                                            Scans by Top Cities
                                        </Typography>
                                        <AnalyticsTable
                                            label="City"
                                            groupedVisits={
                                                analytics.visitsByCity
                                            }
                                            totalVisits={visits.length}
                                            maxRows={
                                                showMore
                                                    ? false
                                                    : ANALYTICS_MAX_ROWS
                                            }
                                        />
                                    </AnalyticsGridItem>
                                </AnalyticsGridRow>
                            ) : null}
                        </AnalyticsGrid>
                    )}
                </Grid>

                <QrItemFooter
                    leftActions={
                        <IconLink
                            icon={<FontAwesomeIcon icon={faArrowLeft} />}
                            text="Back"
                            isFooterLink
                            onClick={() => setParentView(QR_VIEWS.EDIT)}
                        />
                    }
                    rightActions={
                        <IconLink
                            icon={<FontAwesomeIcon icon={faCircleDown} />}
                            text="Download CSV"
                            isFooterLink
                            onClick={() => downloadCsv()}
                        />
                    }
                />
            </QrItemInner>
        </QrItemContainer>
    );
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(QrViewDetails);
