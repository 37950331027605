import React from 'react'
import { Box, Select } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.grey.main,
    borderRadius: '6px',
    color: theme.palette.grey.dark,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '12px',
    height: '42px',
    margin: ' 0 0 10px',
    flexGrow: 1,
    // black border selector
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    // black border hover selector
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    // blue border selector
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
}))

const SelectDropdown = ({ children, handleChange, name, ...rest }) => {
    return (
        <Box>
            <StyledSelect onChange={handleChange} {...rest}>
                {children}
            </StyledSelect>
        </Box>
    )
}

export default SelectDropdown