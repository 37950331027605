import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Box, MenuItem, InputLabel, FormControl, Typography } from '@mui/material'
import { setExt } from '../system/redux/reducers/qrcode'
import QRCodeStyling from "qr-code-styling"
import SelectDropdown from './SelectDropdown'
import { db, storage } from '../system/firebase/index'
import { doc, getDoc } from 'firebase/firestore'
import { downloadQrCode } from '../helpers/functions'
import { getDownloadURL, ref } from 'firebase/storage'

// References: https://codesandbox.io/s/qr-code-styling-react-example-l8rwl?file=/src/App.js:1474-1483
// https://qr-code-styling.com/
// https://github.com/kozakdenys/qr-code-styling
let qrCode = false

if (typeof window !== 'undefined') {
    qrCode = new QRCodeStyling({
        width: 325,
        height: 325,
        image: "",
        dotsOptions: {
            color: "#000",
            type: "square"
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 10,
            hideBackgroundDots: true,
        }
    })
}

const QrCode = ({ dispatch, campaign, baseUrl, urlKey, dateAdded, extension, options, filename = 'qr', showFileExtControls = false }) => {   
    const [url, setUrl] = useState(`${baseUrl}/${urlKey}`)
    const [name, setName] = useState(filename)
    const qrRef = useRef(null)

    useEffect(() => {
        if (qrCode) {
            qrCode.append(qrRef.current);
        }
    }, [])

    useEffect(() => {
        if (showFileExtControls) {
            qrCode.update({
                width: 250,
                height: 250,
            });
        } else {
            qrCode.update({
                width: 325,
                height: 325,
            });
        }
    }, [showFileExtControls])

    useEffect(() => {
        if (urlKey) {
            setUrl(`${baseUrl}/${urlKey}`)
        }
    }, [baseUrl, urlKey])
    useEffect(() => {
        if (filename) {
            setName(filename)
        }
    }, [filename])
    
    useEffect(() => {
        if (qrCode) {
            qrCode.update({
                data: url
            })
        }
    }, [url])

    useEffect(() => {
        if (qrCode) {
            qrCode.update({
                dotsOptions: {
                    type: options.style,
                }
            })
        }
    }, [options.style])

    useEffect(() => {
        if (qrCode) {
            qrCode.update({
                dotsOptions: {
                    color: options.colour,
                }
            })
        }
    }, [options.colour])

    useEffect(() => {
        if (qrCode) {
            if (options.logo) {
                const docRef = doc(db, 'logos', options.logo)
                getDoc(docRef).then((doc) => {
                    if (doc.exists) {
                        getDownloadURL(ref(storage, doc.get('path') + doc.get('filename'))).then((url) => {
                            qrCode.update({
                                image: url,
                            })
                        })
                    }
                })
            } else {
                qrCode.update({
                    image: false,
                })
            }
        }
    }, [options.logo])

    const handleChange = (e) => {
        dispatch(setExt(e.target.value))
    }

    return (
        <Box>
            {
                showFileExtControls
                    ? <Box mr={1}>
                        <FormControl sx={{ width: '90px' }}>
                            <InputLabel sx={{ mt:'-8px' }}>
                                <Typography variant='ibm'>
                                    File Type
                                </Typography>
                            </InputLabel>
                            <SelectDropdown value={extension} handleChange={(e) => {handleChange(e)}} fullWidth>
                                <MenuItem value={'svg'} label="SVG">SVG</MenuItem>
                                <MenuItem value={'png'} label='PNG'>PNG</MenuItem>
                                <MenuItem value={'jpeg'} label='JPEG'>JPEG</MenuItem>
                                <MenuItem value={'webp'} label='WEBP'>WEBP</MenuItem>
                            </SelectDropdown>
                        </FormControl>
                    </Box>
                    : null
            }
            <Box onClick={() => downloadQrCode({ baseUrl, urlKey, filename: name, qrOptions: options, extension: extension })} ref={qrRef} sx={{ cursor: 'pointer' }} />
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        campaign: state.Globals.campaign,
        baseUrl: state.Globals.baseUrl,
        extension: state.QrCode.extension,
        options: state.QrCode.options,
    }
}

export default connect(mapStateToProps)(QrCode)