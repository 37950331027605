import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ChromePicker } from 'react-color'
import { Box, Grid, InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { FILE_FOLDERS, FILE_TYPES, QR_CODE_STYLES, QR_VIEWS } from '../helpers/constants'
import { setColour, setLogo, setStyle } from '../system/redux/reducers/qrcode'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCloudArrowUp, faArrowLeft, faCircleDown } from '@fortawesome/free-solid-svg-icons'
import { constructDownloadName, downloadQrCode } from '../helpers/functions'
import { collection, getDocs, query } from 'firebase/firestore'
import { db, storage } from '../system/firebase/index'
import QrCode from './QrCode'
import IconLink from './IconLink'
import QrItemContainer from './QrItemContainer'
import QrItemHeader from './QrItemHeader'
import QrItemInner from './QrItemInner'
import QrItemFooter from './QrItemFooter'
import { getDownloadURL, ref } from 'firebase/storage'
import FileUploadModal from './FileUploadModal'

const ImageButton = ({ image, onClick, isActive = false }) => {
    const theme = useTheme()

    return (
        <Box onClick={onClick} sx={{
            padding: theme.spacing(2),
            height: '80px',
            width: '80px',
            flexShrink: 0,
            cursor: 'pointer',
            backgroundColor: isActive ? theme.palette.blue.lighter : theme.palette.grey.lightest,
            border: '1px solid',
            borderColor: isActive ? theme.palette.blue.main : theme.palette.grey.lightest,
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: theme.palette.blue.lighter,
                borderColor: theme.palette.blue.main,
            },
            '& > *': {
                width: '100%',
            },
        }} >
            { image }
        </Box>
    )
}

const ColourPicker = ({ colour, onChange }) => {
    const theme = useTheme()
    const [pickerOpen, setPickerOpen] = useState(false)

    return (
        <Box position='relative' display='inline-block'>
            <TextField label="Code Colour" variant="outlined" aria-readonly onClick={() => setPickerOpen(true)} value={colour}
                sx={{ '& input': { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}}
                InputProps={{ endAdornment: <InputAdornment position='end'><Box p={2.5} borderRadius={1} bgcolor={colour} /></InputAdornment> }} 
            />
            { pickerOpen ? <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }} onClick={() => setPickerOpen(false)} /> : null }
            {   
                pickerOpen ? 
                    <Box sx={{ position: 'absolute', zIndex: 99, top: '100%', left: '25%', [theme.breakpoints.up('sm')]: { top: '-75%', left: '100%' }}}>
                        <ChromePicker disableAlpha presetColors={[]} color={colour} onChange={(color) => onChange(color.hex)} /> 
                    </Box>
                    : null 
            }
        </Box>
    )
}

const QrViewCustomise = ({ dispatch, setParentView, data, campaign, baseUrl, qrOptions }) => {
    const theme = useTheme()
    const [logos, setLogos] = useState(false)
    const [logoUploadOpen, setLogoUploadOpen] = useState(false)

    useEffect(() => {
        let isCancelled = false

        async function fetchLogos() {
            const q = query(collection(db, 'logos'))
            const snapshot = await getDocs(q)
            const logos = await Promise.all(snapshot.docs.map(
                async (doc) => {
                    const logo = { docId: doc.id, ...doc.data() }

                    if (logo.filename) {
                        try {
                            logo.url = await getDownloadURL(ref(storage, logo.path + logo.filename))
                        } catch {}
                    }

                    return logo
                }
            ))
            
            if (!isCancelled) {
                setLogos(logos)
            }
        }

        if (logos === false) {
            fetchLogos()
        }

        return () => { isCancelled = true; }
    }, [logos])

    const handleLogoUpload = (logo) => {
        // we're optimistically adding the logo to the list, but we could also just reload logos from the database with setLogos(false)
        getDownloadURL(ref(storage, logo.path + logo.filename)).then((url) => {
            logo.url = url
            setLogos(logos ? [...logos, logo] : [logo])
        })
    }

    return (
        <QrItemContainer>
            <QrItemHeader
                title={`Customise QR Code | ${data.name}`}
                rightActions={<IconLink icon={<FontAwesomeIcon icon={faArrowLeft} />} text='Back' onClick={() => setParentView(QR_VIEWS.EDIT)} />}
            />
            <QrItemInner>
                <Box display='flex' flexWrap='wrap' mb={5}>
                    <Box sx={{ width: '100%', [theme.breakpoints.up('sm')]: { width: '66.666666%' }}}>
                        <Typography display='block' variant='innerHeader'>QR Code</Typography>
                        <Box display='flex' mt={1} sx={{ overflowX: 'auto', '&>* + *': { marginLeft: theme.spacing(3) }}}>
                            <ImageButton image={<StaticImage src='../assets/images/qrStyleSquare.png' alt='Square' />} isActive={qrOptions.style === QR_CODE_STYLES.SQUARE} onClick={() => dispatch(setStyle(QR_CODE_STYLES.SQUARE))} />
                            <ImageButton image={<StaticImage src='../assets/images/qrStyleDots.png' alt='Dots' />} isActive={qrOptions.style === QR_CODE_STYLES.DOTS} onClick={() => dispatch(setStyle(QR_CODE_STYLES.DOTS))} />
                            <ImageButton image={<StaticImage src='../assets/images/qrStyleRounded.png' alt='Rounded' />} isActive={qrOptions.style === QR_CODE_STYLES.ROUNDED} onClick={() => dispatch(setStyle(QR_CODE_STYLES.ROUNDED))} />
                            <ImageButton image={<StaticImage src='../assets/images/qrStyleExtraRounded.png' alt='Extra Rounded' />} isActive={qrOptions.style === QR_CODE_STYLES.EXTRA_ROUNDED} onClick={() => dispatch(setStyle(QR_CODE_STYLES.EXTRA_ROUNDED))} />
                            <ImageButton image={<StaticImage src='../assets/images/qrStyleClassy.png' alt='Classy' />} isActive={qrOptions.style === QR_CODE_STYLES.CLASSY} onClick={() => dispatch(setStyle(QR_CODE_STYLES.CLASSY))} />
                            <ImageButton image={<StaticImage src='../assets/images/qrStyleClassyRounded.png' alt='Classy Rounded' />} isActive={qrOptions.style === QR_CODE_STYLES.CLASSY_ROUNDED} onClick={() => dispatch(setStyle(QR_CODE_STYLES.CLASSY_ROUNDED))} />
                        </Box>

                        <Box mt={4} mb={6}>
                            <ColourPicker colour={qrOptions.colour} onChange={(colour) => dispatch(setColour(colour))} />
                        </Box>

                        <Box display='flex' mt={1}>
                            <Typography display='block' variant='innerHeader'>Logo</Typography>
                            <Box display='inline' borderLeft={`2px solid ${theme.palette.grey.lightest}`} my='2px' mx={2} />
                            <Typography display='flex' justifyContent='center' alignItems='center' color={theme.palette.blue.dark} fontWeight='700' fontSize={14} onClick={() => setLogoUploadOpen(true)} sx={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faCloudArrowUp} color={theme.palette.blue.main} size='sm' /><span style={{ marginLeft: theme.spacing(1) }}>Upload</span></Typography>
                            <FileUploadModal open={logoUploadOpen} setParentOpen={setLogoUploadOpen} parentFileHandler={handleLogoUpload} label='Logo' uploadCollection={FILE_FOLDERS.LOGOS} fileType={FILE_TYPES.IMAGE} />
                        </Box>
                        <Box display='flex' mt={1} sx={{ overflowX: 'auto', '&>* + *': { marginLeft: theme.spacing(3) }}}>
                            <ImageButton image={<FontAwesomeIcon icon={faBan} size='2x' color={theme.palette.grey.darkLite} />} isActive={qrOptions.logo === false} onClick={() => dispatch(setLogo(false))} />
                            {
                                logos !== false ? 
                                    logos.map((logo) => 
                                        logo.url
                                            ? <ImageButton key={logo.docId} isActive={qrOptions.logo === logo.docId} onClick={() => dispatch(setLogo(logo.docId))}
                                                image={<img src={logo.url} alt={logo.filename} />}
                                            />
                                            : null
                                    )
                                    : null

                            }
                        </Box>
                    </Box>
                    <Box align='right' sx={{ width: '100%', marginTop: theme.spacing(4), [theme.breakpoints.up('sm')]: { width: '33.333333%', marginTop: 0, paddingRight: theme.spacing(4) }}}>
                        <QrCode displayOnly urlKey={data.urlKey} filename={constructDownloadName(data.name, data.date_added)} />
                    </Box>
                </Box>

                <QrItemFooter
                    leftActions={<IconLink icon={<FontAwesomeIcon icon={faArrowLeft} />} text='Back' isFooterLink onClick={() => setParentView(QR_VIEWS.EDIT)} />} 
                    rightActions={<IconLink icon={<FontAwesomeIcon icon={faCircleDown} />} text='Download QR Code' isFooterLink onClick={() => downloadQrCode({ baseUrl, urlKey: data.urlKey, filename: constructDownloadName(data.name, data.date_added), qrOptions })} />} 
                />
            </QrItemInner>
        </QrItemContainer>
    )
}

function mapStateToProps(state) {
    return {
        campaign: state.Globals.campaign,
        baseUrl: state.Globals.baseUrl,
        qrOptions: state.QrCode.options,
    }
}

export default connect(mapStateToProps)(QrViewCustomise)