import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../templates/Master';
import {
    Box,
    Button,
    Grid,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Backdrop,
    Autocomplete,
    TextField,
} from '@mui/material';
import { setIsAddNew, setIsEdit } from '../system/redux/reducers/globals';
import InputUnstyled from '@mui/base/InputUnstyled';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    onSnapshot,
    query,
    collection,
    orderBy,
    where,
    doc,
} from 'firebase/firestore';
import { StaticImage } from 'gatsby-plugin-image';
import { db } from '../system/firebase/index';
import UrlConstruct from '../components/UrlConstruct';
import QrCodeDownloader from '../components/QrCodeDownloader';
import SelectDropdown from '../components/SelectDropdown';
import Meta from '../components/Meta';
import QrViewHandler from '../components/QrViewHandler';
import { QR_VIEWS } from '../helpers/constants';
import { constructDownloadName, downloadQrCode } from '../helpers/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faQrcode } from '@fortawesome/free-solid-svg-icons';
import IconLink from '../components/IconLink';
import moment from 'moment';

const Input = styled(InputUnstyled)(({ theme }) => ({
    'input, textarea': {
        backgroundColor: theme.palette.grey.main,
        border: 'none',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        color: theme.palette.grey.dark,
        fontFamily: 'IBM Plex sans',
        fontWeight: 400,
        fontSize: '14px',
        height: '42px',
        margin: ' 0 0 10px',
        outline: 'none',
        padding: '0 12px',
        width: '100%',

        '&::placeholder': {
            color: theme.palette.grey.dark,
        },
    },
}));

const ListingsPage = ({ edit, addNew, dispatch, location, baseUrl }) => {
    const theme = useTheme();
    const desktopView = useMediaQuery(theme.breakpoints.up('lg'));

    const [listings, setListings] = useState([]);
    const [filteredListings, setFilteredListings] = useState({
        unfiltered: [],
        filtered: [],
    });
    const [catOptions, setCatOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sort, setSort] = useState({
        sortBy: 'date_added_sortable',
        direction: 'desc',
        campaign: 'All',
    });

    useEffect(() => {
        const userRef = doc(db, 'campaign', 'tags');
        const unsubscribe = onSnapshot(userRef, (userSnapshot) => {
            const tags = userSnapshot.data();
            setCatOptions(tags);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        const constraints = [];
        constraints.push(where('deleted', '!=', true));
        if (sort.campaign && sort.campaign !== 'All') {
            constraints.push(where('campaign', '==', sort.campaign));
        }
        const q = query(
            collection(db, 'listings'),
            orderBy('deleted', 'asc'),
            orderBy('date_added', 'desc'),
            ...constraints
        );
        onSnapshot(q, (snapshot) => {
            const output = [];
            snapshot.docs.map((doc) => {
                const listingData = doc.data();
                listingData.docId = doc.id;
                listingData.name_sortable = listingData.name.toLowerCase();
                listingData.urlKey_sortable = listingData.urlKey.toLowerCase();
                listingData.date_added_sortable = moment(
                    listingData.date_added,
                    'DD/MM/YYYY'
                ).format('X');

                return output.push(listingData);
            });
            sortList(sort.sortBy, sort.direction, output);
            setFilteredListings({ unfiltered: output });
        });
    }, [sort.campaign]);

    useEffect(() => {
        if (listings && searchTerm) {
            const originalListings = [...filteredListings.unfiltered];

            const output = originalListings.filter((item) =>
                item.name.toLowerCase().includes(searchTerm)
            );
            const output2 = originalListings.filter((item) =>
                item.urlKey.toLowerCase().includes(searchTerm)
            );
            let results = [...output, ...output2];

            // filtering out the duplicates
            results = results.filter((item, index) => {
                return results.indexOf(item) === index;
            });

            setFilteredListings({ ...filteredListings, filtered: results });
            sortList(sort.sortBy, sort.direction, results);
        }
        if (!searchTerm) {
            sortList(sort.sortBy, sort.direction, filteredListings.unfiltered);
        }
    }, [searchTerm]);

    const sortList = (key, direction, listObj) => {
        listObj.sort((a, b) => {
            if (direction === 'asc') {
                if (a[key] < b[key]) {
                    return -1;
                }
            } else {
                if (b[key] < a[key]) {
                    return -1;
                }
            }
        });
        setListings(listObj);
    };

    const handleChange = (e, type) => {
        if (e.target.value === 'Sort By') {
            return;
        }
        let key = type === 'direction' ? sort.sortBy : e.target.value;
        let direction = type === 'direction' ? e.target.value : sort.direction;
        sortList(key, direction, listings);
        setSort({ ...sort, [type]: e.target.value });
    };
    const handleSearch = (e) => {
        const string = e.target.value.toLowerCase();
        setSearchTerm(string);
    };
    const Select = (
        <FormControl fullWidth>
            <InputLabel sx={{ mt: '-8px' }}>
                <Typography variant="ibm">Sort By</Typography>
            </InputLabel>
            <SelectDropdown
                value={sort.sortBy}
                handleChange={(e) => {
                    handleChange(e, 'sortBy');
                }}
                fullWidth
            >
                <MenuItem value={'date_added_sortable'} label="Date Added">
                    Date Added
                </MenuItem>
                <MenuItem value={'urlKey_sortable'} label="URL Key">
                    URL Key
                </MenuItem>
                <MenuItem value={'name_sortable'} label="Name">
                    Name
                </MenuItem>
            </SelectDropdown>
        </FormControl>
    );

    const SelectDir = (
        <FormControl fullWidth>
            <InputLabel sx={{ mt: '-8px' }}>
                <Typography variant="ibm">Order</Typography>
            </InputLabel>
            <SelectDropdown
                value={sort.direction}
                handleChange={(e) => {
                    handleChange(e, 'direction');
                }}
                fullWidth
            >
                <MenuItem value={'desc'} label="Descending">
                    Desc
                </MenuItem>
                <MenuItem value={'asc'} label="Ascending">
                    ASC
                </MenuItem>
            </SelectDropdown>
        </FormControl>
    );
    const SelectCampaign = (
        <FormControl fullWidth>
            <InputLabel sx={{ mt: { mobile: '-8px', laptop: '-40px' } }}>
                <Typography
                    fontSize={{ mobile: '', laptop: '10px !important' }}
                    variant="ibm"
                >
                    Search and Filter By Tag
                </Typography>
            </InputLabel>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={catOptions.options}
                sx={{
                    '.MuiAutocomplete-inputRoot': {
                        backgroundColor: theme.palette.grey.main,
                        borderRadius: '6px',
                        color: theme.palette.grey.dark,
                        height: '42px',
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '12px',
                        padding: ' 0 10px',
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                        },
                    },
                }}
                onChange={(event, newInputValue) => {
                    if (newInputValue && newInputValue.value) {
                        setSort({ ...sort, campaign: newInputValue.value });
                    } else {
                        setSort({ ...sort, campaign: 'All' });
                    }
                }}
                renderInput={(params) => <TextField {...params} label="" />}
                value={sort.campaign}
            />
        </FormControl>
    );
    return (
        <Layout location={location}>
            <Grid
                container={true}
                height="100%"
                justifyContent="space-between"
                mb={4}
                width="100%"
            >
                <Grid item pr={1} xs={desktopView ? 1 : 2}>
                    <Button
                        onClick={() => dispatch(setIsAddNew(true))}
                        variant="secondary"
                    >
                        + Add New
                    </Button>
                </Grid>
                <Grid
                    display={desktopView ? 'none' : 'block'}
                    item
                    pr={1}
                    width={1 / 4}
                    xs={3}
                >
                    {Select}
                </Grid>
                <Grid
                    display={desktopView ? 'none' : 'block'}
                    item
                    pr={1}
                    width={1 / 4}
                    xs={3}
                >
                    {SelectDir}
                </Grid>
                <Grid
                    display={desktopView ? 'none' : 'block'}
                    item
                    pr={1}
                    width={1 / 4}
                    xs={4}
                >
                    {SelectCampaign}
                </Grid>
                <Grid
                    container={true}
                    item
                    justifyContent={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'space-between',
                    ]}
                    spacing={3}
                    xs={8}
                >
                    <Grid
                        item
                        xs={2}
                        width="100%"
                        display={desktopView ? 'block' : 'none'}
                    >
                        {Select}
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        width="100%"
                        display={desktopView ? 'block' : 'none'}
                    >
                        {SelectDir}
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        width="100%"
                        display={desktopView ? 'block' : 'none'}
                    >
                        {SelectCampaign}
                    </Grid>
                    <Grid
                        container={true}
                        item
                        xs={4}
                        width={desktopView ? 'auto' : '100%'}
                    >
                        <Grid
                            bgcolor="grey.main"
                            container={true}
                            flexWrap="nowrap"
                            height="42px"
                            justifyContent="space-between"
                            sx={{ borderRadius: '6px' }}
                        >
                            <Grid item xs={10.5}>
                                <Input
                                    onChange={(e) => handleSearch(e)}
                                    autoComplete="off"
                                    placeholder="Search"
                                    type="text"
                                ></Input>
                            </Grid>
                            <Grid
                                alignItems="center"
                                bgcolor="grey.main"
                                container={true}
                                item
                                width={0.3 / 3}
                                xs={1.5}
                                sx={{
                                    borderTopRightRadius: '6px',
                                    borderBottomRightRadius: '6px',
                                }}
                            >
                                <StaticImage
                                    src="../assets/svg/icn_search.svg"
                                    alt=""
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {addNew ? <QrViewHandler initialView={QR_VIEWS.ADD} /> : null}
            {listings
                ? listings.map((listing, i) => {
                      return (
                          <Box key={i}>
                              {edit === i ? (
                                  <QrViewHandler
                                      initialView={QR_VIEWS.EDIT}
                                      data={listing}
                                  />
                              ) : (
                                  <Box
                                      p={2}
                                      mb={2}
                                      onClick={() => dispatch(setIsEdit(i))}
                                      border={`1px solid ${theme.palette.grey.darkLite}`}
                                      borderRadius="6px"
                                      sx={{ cursor: 'pointer' }}
                                  >
                                      <Box
                                          display="flex"
                                          flexWrap="wrap"
                                          alignItems="center"
                                      >
                                          <Box
                                              align="right"
                                              overflow="hidden"
                                              textOverflow="ellipsis"
                                              whiteSpace="nowrap"
                                              pr={3}
                                              title={listing.urlKey}
                                              sx={{
                                                  width: '25%',
                                                  [theme.breakpoints.up('sm')]:
                                                      { width: '8.333333%' },
                                              }}
                                          >
                                              {listing.urlKey}
                                          </Box>
                                          <Box
                                              textTransform="uppercase"
                                              overflow="hidden"
                                              textOverflow="ellipsis"
                                              whiteSpace="nowrap"
                                              pl={3}
                                              title={listing.name}
                                              borderLeft={`1px solid ${theme.palette.grey.darkLite}`}
                                              sx={{
                                                  width: '75%',
                                                  [theme.breakpoints.up('sm')]:
                                                      { width: '41.666666%' },
                                              }}
                                          >
                                              {listing.name}
                                          </Box>
                                          <Box
                                              textTransform="uppercase"
                                              overflow="hidden"
                                              textOverflow="ellipsis"
                                              whiteSpace="nowrap"
                                              sx={{
                                                  width: '100%',
                                                  marginTop: theme.spacing(1),
                                                  [theme.breakpoints.up('sm')]:
                                                      {
                                                          width: '33.333333%',
                                                          marginTop: 0,
                                                      },
                                              }}
                                          >
                                              URL:{' '}
                                              <UrlConstruct
                                                  urlKey={listing.urlKey}
                                                  sx={{
                                                      pointerEvents: 'none',
                                                      [theme.breakpoints.up(
                                                          'sm'
                                                      )]: {
                                                          pointerEvents:
                                                              'initial',
                                                      },
                                                  }}
                                              />
                                          </Box>
                                          {desktopView && (
                                              <Box
                                                  display="flex"
                                                  width="16.666666%"
                                                  justifyContent="flex-end"
                                                  alignItems="center"
                                                  spacing={1}
                                                  color="grey.darkLite"
                                              >
                                                  <IconLink
                                                      icon={
                                                          <FontAwesomeIcon
                                                              icon={faQrcode}
                                                              size="2x"
                                                          />
                                                      }
                                                      onClick={() =>
                                                          downloadQrCode({
                                                              baseUrl,
                                                              urlKey: listing.urlKey,
                                                              filename:
                                                                  constructDownloadName(
                                                                      listing.name,
                                                                      listing.date_added
                                                                  ),
                                                              qrOptions:
                                                                  listing.qrOptions ||
                                                                  {},
                                                          })
                                                      }
                                                  />
                                                  <IconLink
                                                      icon={
                                                          <FontAwesomeIcon
                                                              icon={faPen}
                                                          />
                                                      }
                                                      onClick={() =>
                                                          dispatch(setIsEdit(i))
                                                      }
                                                  />
                                              </Box>
                                          )}
                                      </Box>
                                  </Box>
                              )}
                          </Box>
                      );
                  })
                : null}
            <Grid container justifyContent="center" mt={4}>
                <Grid
                    container
                    justifyContent="center"
                    border={`1px solid ${theme.palette.grey.lighter}`}
                    color="grey.lighter"
                    width="30px"
                    padding="6px 0"
                    borderRadius="15px"
                    onClick={() => dispatch(setIsAddNew(true))}
                    sx={{ cursor: 'pointer' }}
                >
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                </Grid>
            </Grid>
            <QrCodeDownloader />
            <Backdrop
                sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 1 }}
                open={addNew || edit !== false}
            />
        </Layout>
    );
};

function mapStateToProps(state) {
    return {
        campaign: state.Globals.campaign,
        baseUrl: state.Globals.baseUrl,
        edit: state.Globals.isEdit,
        addNew: state.Globals.isAddNew,
    };
}

export default connect(mapStateToProps)(ListingsPage);

export const Head = ({ location }) => {
    return <Meta location={location} pageTitle="Listings Page" />;
};
