import * as React from 'react';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../system/firebase';
import { FILE_FOLDERS, FILE_TYPES } from '../helpers/constants';
import { collection, doc, setDoc } from 'firebase/firestore';
import { formatDate } from '../helpers/functions';

const FileUploadModal = ({ open, setParentOpen, parentFileHandler, label = '', uploadCollection, fileType = null }) => {
    const theme = useTheme()
    const [acceptedFiles, setAcceptedFiles] = useState(null);
    const [file, setFile] = useState(null)
    const [fileUrl, setFileUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        if (fileType) {
            if (fileType === FILE_TYPES.IMAGE) {
                setAcceptedFiles('image/*')
            } else if (fileType === FILE_TYPES.PDF) {
                setAcceptedFiles('application/pdf')
            }
        }
    }, [fileType])

    useEffect(() => {
        if (file && fileType === FILE_TYPES.IMAGE) {
            setFileUrl(URL.createObjectURL(file));
        }
    }, [file, fileType]);

    useEffect(() => {
        let isCancelled = false;

        if (uploading && file) {
            const uuid = crypto.randomUUID()
            const storageRef = ref(storage, `${uploadCollection}/${uuid}/${file.name}`)
            const uploadTask = uploadBytesResumable(storageRef, file)

            uploadTask.on('state_changed',
                (snapshot) => { // uploading
                    if (isCancelled) return;

                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                },
                (error) => { // error
                    // TODO: Handle error
                },
                () => { // uploaded
                    if (isCancelled) return;

                    const filePathElements = uploadTask.snapshot.ref.fullPath.split('/')
                    const filename = filePathElements.pop()
                    const fileData = {
                        date_added: formatDate(new Date()),
                        path: filePathElements.join('/') + '/',
                        filename,
                    }

                    // we need to cache the download URL here because the [redirect.js] page does not have the auth required to create it on the fly
                    getDownloadURL(ref(storage, fileData.path + fileData.filename)).then((url) => {
                        fileData.cachedUrl = url
                        fileData.urlCacheDate = formatDate(new Date()) // this is future proofing for if we expire URLs

                        const docRef = doc(collection(db, uploadCollection))
                        setDoc(docRef, fileData).then(() => {
                            parentFileHandler({ docId: docRef.id, ...fileData })
                            reset()
                            setParentOpen(false)
                        })
                    })
                }
            )
        }

        return () => { isCancelled = true }
    }, [uploading, file])

    const reset = () => {
        setFile(null);
        setFileUrl(null);
        setUploading(false);
        setProgress(null);
    }

    const handleClose = () => {
        if (uploading) return;

        reset()
        setParentOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle textAlign='center'>
                <Typography textTransform='uppercase'>Upload { label }</Typography>
            </DialogTitle>
            <DialogContent style={{ paddingBottom: 0, textAlign: 'center' }}>
                <input type="file" accept={acceptedFiles} id="choose-file" style={{ display: 'none' }} onChange={(e) => setFile(e.target.files[0])} />
                <label htmlFor="choose-file" >
                    <Button variant='contained' color='primary' htmlFor='choose-file' component="span">Choose { label }</Button>
                </label>
                {
                    file && (
                        <Box mt={4} textAlign="center">
                            {
                                fileUrl && fileType === FILE_TYPES.IMAGE
                                    ? <img src={fileUrl} alt={file.name} height="100px" />
                                    : <Typography>{ file.name }</Typography>
                            }
                        </Box>
                    )
                }
                {
                    uploading && (
                        <LinearProgress variant={progress ? "determinate" : "indeterminate"} value={progress} style={{ marginTop: theme.spacing(2) }} />
                    )
                }
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleClose} color='red' disabled={uploading}>Cancel</Button>
                <LoadingButton onClick={() => setUploading(true)} disabled={!file} loading={uploading}><span>Upload</span></LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(FileUploadModal)