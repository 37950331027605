import { Box, useTheme } from "@mui/material"
import React from "react"

const QrItemFooter = ({ leftActions, rightActions }) => {
    const theme = useTheme()

    return (
        <Box display='flex' color={theme.palette.grey.dark} pt={2} borderTop={`1px solid ${theme.palette.grey.lighter}`}>
            <Box display='flex' flex='1' sx={{ 
                flexDirection:'column', 
                '& svg path': { fill: theme.palette.grey.lite }, 
                '&>* + *': { paddingTop: `${theme.spacing(3)}` }, 
                [theme.breakpoints.up('sm')]: { 
                    flexDirection: 'row', 
                    '&>* + *': { 
                        paddingTop: '0px !important', 
                        paddingLeft: `${theme.spacing(8)}` 
                    }
                }
            }}>
                { leftActions }
            </Box>
            <Box display='flex' flex='1' sx={{ 
                flexDirection:'column', 
                justifyContent:'flex-start', 
                alignItems:'flex-end', 
                '& svg path': { fill: theme.palette.grey.lite }, 
                '&>* + *': { paddingTop: `${theme.spacing(3)}` }, 
                [theme.breakpoints.up('sm')]: { 
                    flexDirection: 'row', 
                    justifyContent:'flex-end', 
                    '&>* + *': { 
                        paddingTop: '0px !important', 
                        paddingLeft: `${theme.spacing(8)}` 
                    }
                }
            }}>
                { rightActions }
            </Box>
        </Box>
    )
}

export default QrItemFooter