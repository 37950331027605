import React from 'react'
import { Box, Grid, Typography, useTheme } from "@mui/material"

const IconLink = ({ icon, text, onClick, isFooterLink = false, iconSide = 'left' }) => {
    const theme = useTheme()

    return (
        <Grid item onClick={onClick} sx={{ 
            display: 'flex', 
            cursor: 'pointer',
            '&:hover .hover-gradient': isFooterLink ? {
                background: theme.palette.gradient.main,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            } : null,
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                { iconSide === 'left' ? icon : null }
                <Typography ml={iconSide === 'left' ? (isFooterLink ? '5px' : '8px') : null} mr={iconSide === 'right' ? (isFooterLink ? '5px' : '8px') : null} variant={isFooterLink ? 'link' : 'headerLink'} className='hover-gradient'>{ text }</Typography>
                { iconSide === 'right' ? icon : null }
            </Box>
        </Grid>
    )
}

export default IconLink