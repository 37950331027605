import React from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../models/Users'
import { Box, Grid, Typography } from '@mui/material'
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ dispatch }) => {
    const logout =() => {
        logoutUser(dispatch)
    }
    return (
        <Grid container={true} height='100%' width='100%' justifyContent= 'space-between'>
            <Grid item xs={6}>
                <Box>
                    <Box>
                        <StaticImage src="../assets/images/barcodeBlack.png" alt="" />
                    </Box>
                    <Box ml={-1}>
                        <StaticImage src="../assets/images/logoBlackLrg.png" alt="B!ink Management Platform" />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={6} textAlign='right' sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                <Box onClick={logout} sx={{ cursor: 'pointer' }}>LOGOUT</Box>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(Header)
