import { Box, useTheme } from "@mui/material"
import React from "react"

const QrItemContainer = ({ children, component = null, onSubmit = null }) => {
    const theme = useTheme()

    return (
        <Box component={component} onSubmit={onSubmit} sx={{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            borderStyle: 'solid',
            borderColor: theme.palette.grey.darkLite,
            borderWidth: '1px',
            borderRadius: '4px',
            width:'100%',
            zIndex: 99,
            position: 'relative'
        }}>
            { children }
            <Box height="4px" width="100%" sx={{
                background: 'linear-gradient(90deg, #AD00FF 0%, #2400FF 118.18%)',
                borderRadius: '4px',
                borderStyle: 'solid',
                borderColor: 'grey.darkLite',
                borderWidth: '1px',
                borderBottom: '0px',
                borderLeft: '0px',
                borderRight: '0px'
            }} />
        </Box>
    )
}

export default QrItemContainer