import { Grid } from "@mui/material"
import React from "react"

const QrItemInner = ({ children }) => {
    return (
        <Grid bgcolor='white.main' px={[2, 2, 2, 2, 2, 5]} pt={[2, 2, 2, 2, 2, 5]} pb={[2, 2, 2, 2, 2, 3]} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
            { children }
        </Grid>
    )
}

export default QrItemInner