import { Grid, Typography, useTheme } from "@mui/material"
import React from "react"

const QrItemHeader = ({ title, leftActions, rightActions }) => {
    const theme = useTheme()

    return (
        <Grid display='flex' color={theme.palette.white.main} px={[2, 2, 2, 2, 2, 5]} py={2} sx={{ background: theme.palette.gradient.main }}>
            <Grid display='flex' flex='1' sx={{ '&>* + *': { marginLeft: `${theme.spacing(3)} !important` }}}>
                <Typography textTransform='uppercase'>{ title }</Typography>
                { leftActions }
            </Grid>
            <Grid display='flex' flex='1' justifyContent='flex-end' sx={{ '&>* + *': { marginLeft: `${theme.spacing(3)} !important` }, '& svg': { fill: theme.palette.white.main }}}>
                { rightActions }
            </Grid>
        </Grid>
    )
}

export default QrItemHeader