import React from 'react'
import { TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const Auto = ({ catOptions, inputs, setInputs }) => {
    const theme = useTheme()
    const filter = createFilterOptions();

    return (
        <Autocomplete
            value={inputs.campaign}
            sx={{
                width: { mobile: 100, laptop: 300 },
                '.MuiAutocomplete-inputRoot':{
                    backgroundColor: theme.palette.grey.main,
                    borderRadius: '6px',
                    color: theme.palette.grey.dark,
                    height: '42px',
                    padding: ' 0 10px',
                    // black border selector
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    // black border hover selector
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    // blue border selector
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                }
            }}
            onChange={(event, newValue) => {
                const formatString = (string) => {
                    const lowercaseString = string.toLowerCase()
                    const formattedString = lowercaseString.replace(/ /g, "-")
                    return formattedString
                }
                if (typeof newValue === 'string') {
                    const input = formatString(newValue)
                    setInputs({ ...inputs, campaign: input })
                } else if (newValue && newValue.value) {
                    const input = formatString(newValue.value)
                    setInputs({ ...inputs, campaign: input })
                } else if (newValue && newValue.inputValue) {
                    const input = formatString(newValue.inputValue)
                    setInputs({ ...inputs, campaign: input })
                } else {
                    const input = newValue
                    setInputs({ ...inputs, campaign: input })
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            componentsProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: false
                        },
                    ]
                }
            }}
            clearOnBlur
            handleHomeEndKeys
            id="campaign"
            options={catOptions.options}
            getOptionLabel={(option) => {
            // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.label;
            }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="" />
            )}
        />
    )
}

export default Auto