import React from 'react'
import { connect } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import { getVersion } from '../version'
import { StaticImage } from "gatsby-plugin-image"
import moment from 'moment'

const Footer = () => {
    const year = moment().year()
    return (
        <Box maxWidth="xxl" pb={2}>
            <Grid container={true} height='100%' width='100%' justifyContent='space-between'>
                <Grid item xs={6}>
                    <Box>
                        <StaticImage src="../assets/images/logoBlack.png" alt="Barrows" />
                    </Box>
                    <Box mt='-15px'>
                        <Typography variant='small'>{getVersion()}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} textAlign='right' sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography mt={1} variant='small' textTransform='uppercase'>&copy; Barrows Global {year}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(Footer)
