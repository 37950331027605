import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { QR_VIEWS } from "../helpers/constants"
import { resetQrCode, setQrOptions } from '../system/redux/reducers/qrcode'
import QrViewAddEdit from "./QrViewAddEdit"
import QrViewCustomise from "./QrViewCustomise"
import QrViewDetails from "./QrViewDetails"

const QrViewHandler = ({ dispatch, initialView = QR_VIEWS.ADD, data }) => {
    const [view, setView] = useState(initialView)

    useEffect(() => {
        if (data && data.qrOptions) {
            dispatch(setQrOptions(data.qrOptions))
        } else {
            dispatch(resetQrCode())
        }
    }, [data])

    const getViewOutput = (view) => {
        switch(view) {
        case QR_VIEWS.CUSTOMISE:
            return <QrViewCustomise setParentView={setView} data={data} />
        case QR_VIEWS.DETAILS:
            return <QrViewDetails setParentView={setView} data={data} />
        case QR_VIEWS.EDIT:
            return <QrViewAddEdit setParentView={setView} data={data} type='edit' />
        case QR_VIEWS.ADD:
        default:
            return <QrViewAddEdit setParentView={setView} data={data} type='add' />
        }
    }

    return getViewOutput(view)
}

export default connect()(QrViewHandler)